import { noBaseUrl } from "lib/site/baseUrl";

export default function categorize(url) {
  let category, name;
  // Remove the base path and leading `/`.
  // The resulting array is the segments of the route.
  const path = noBaseUrl(url).replace("/", "").split("/");

  if (path) {
    // Home
    if (path[0] === "" && path[1] === undefined) {
      name = "Home";
      category = "MainFeeds";
    }
    // Exec
    else if (path[0] === "exec") {
      name = "Exec";
      category = "Benefits";
    }

    // Members
    else if (path[0] === "members") {
      name = "MembersList";
      category = "MembersList";
      if (path[1]) {
        name = "MemberDetails";
      }
    }
    // Messages
    else if (path[0] === "messages") {
      name = "MessagesList";
      category = "Messages";
      if (path[1] === "new") {
        name = "MessageCreate";
      }
    }
    // Profile
    else if (path[0] === "profile" && path[1] === undefined) {
      name = "MemberFeed";
      category = "MyProfile";
    } else if (path[0] === "profile" && path[1]) {
      name = "MemberFeed";
      category = "MemberProfile";
    }
    // Publishing
    else if (path[0] === "publishing") {
      name = "Dashboard";
      category = "Publishing";
      if (path[1] === "expert-panels") {
        name = "EPQuestionsList";
        if (path[2]) {
          name = "EPAnswerQuestion";
        }
      }
    }
    // Search
    else if (path[0].match(/search/)) {
      name = "SearchResults";
      category = "Search";
    }
    // Groups
    else if (path[0] === "groups") {
      category = "GroupFeed";
      if (path[1]) {
        name = "GroupFeed";
      }
    }
    // Guidelines
    else if (path[0] === "guidelines") {
      category = "Resources";
      name = "Guidelines";
    }

    return { category, name };
  }
}
