import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { QRCodeCanvas } from "qrcode.react";
import { ChangeEvent, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import Close from "components/icons/Close";
import { USER_SMS_MOBILE_APP_LINK_MUTATION } from "graphql/user/mutations";
import { useApp } from "lib/common/appProvider";

import "react-phone-number-input/style.css";

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  width: "100%",

  "& .PhoneInputCountry": {
    outline: `1px solid ${theme.palette.grey[600]}`,
    padding: theme.spacing(1.25, 1.5),
    borderRadius: "4px",
    "& .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow":
      {
        color: theme.palette.primary.main,
      },
  },
  "& input": {
    ...theme.typography.body1,
    width: "100%",
    border: "1px solid transparent",
    borderRadius: "4px",
    padding: "8.5px 14px",
    outline: `1px solid ${theme.palette.grey[600]}`,
    marginLeft: theme.spacing(1.5),
    height: "40px",
  },
  "& input::placeholder": {
    ...theme.typography.body1,
  },
  "&.PhoneInput--focus input, &.PhoneInput:hover input, &.PhoneInput--focus .PhoneInputCountry, &.PhoneInput:hover .PhoneInputCountry":
    {
      outlineColor: theme.palette.primary.main,
    },
  "&.PhoneInput--focus input, &.PhoneInput--focus .PhoneInputCountry": {
    outlineWidth: "2px",
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function MobileDownloadDialog({ open, onClose }: Props) {
  const { permSmsMobileAppLink } = useFlags();
  const { mobileDownloadUrl } = useApp("site");
  const snackbarRef = useApp("snackbarRef");
  const [phone, setPhone] = useState("");
  const [checked, setChecked] = useState(false);
  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const [sendDownloadLink, { loading }] = useMutation(
    USER_SMS_MOBILE_APP_LINK_MUTATION
  );

  const handleClose = () => {
    onClose();
    setPhone("");
    setChecked(false);
  };

  const handleSendDownloadLink = async () => {
    try {
      const response = await sendDownloadLink({
        variables: {
          input: {
            mobilePhoneNumber: phone,
          },
        },
      });

      if (response.data.smsMobileAppLink) {
        snackbarRef?.current?.callSnackbar(
          "Mobile download link successfully sent.",
          "success"
        );
        handleClose();
      }
    } catch {
      snackbarRef?.current?.callSnackbar(
        "Something went wrong, try again.",
        "error"
      );
    }
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          px: isSmDown ? 2 : 7.5,
          py: 4,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          aria-label="close"
          size="small"
          sx={{ p: 0 }}
          onClick={handleClose}
        >
          <Close sx={{ fontSize: "14px" }} />
        </IconButton>
      </Box>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        variant="h3"
      >
        Download the mobile app!
      </Typography>
      <Box
        sx={{
          pt: 2,
          px: 0,
          width: "100%",
        }}
      >
        <Typography textAlign="center" variant="body2">
          {isSmDown
            ? "Click the button below to access the app store and download our app"
            : "Scan the QR code with your phone to access the app store and download our app"}
          {permSmsMobileAppLink
            ? ", or enter your mobile number and we will text you a direct link."
            : "."}
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          mb={4.5}
          mt={isSmDown ? 2 : 4.5}
        >
          {isSmDown ? (
            <Button
              className="pendo_mobileweb-downloadapp-gotostore"
              href={mobileDownloadUrl}
              size="medium"
              target="_blank"
              variant="contained"
            >
              Download app
            </Button>
          ) : (
            <QRCodeCanvas size={150} value={mobileDownloadUrl} />
          )}
        </Box>

        {permSmsMobileAppLink && (
          <>
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              px={isSmDown ? 0 : "60px"}
            >
              <Typography mb={1} variant="body1Semibold">
                Enter your mobile number
              </Typography>

              <Box alignItems="center" display="flex" width="100%">
                <StyledPhoneInput
                  smartCaret
                  defaultCountry="US"
                  placeholder="+ "
                  value={phone}
                  onChange={setPhone}
                />
              </Box>
              <Box
                sx={{ mt: 1, gap: 1, display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  componentsProps={{
                    typography: { variant: "caption" },
                  }}
                  control={
                    <Checkbox
                      checked={checked}
                      sx={{
                        p: 0,
                        mx: 1,
                        alignSelf: "flex-start",
                      }}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="By opting in, you agree to receive a one-time message with a
                  download link for our mobile app. Standard messaging rates may
                  apply."
                  sx={{ mr: 0 }}
                />
              </Box>
            </Box>

            <Box sx={{ pb: 0, pt: 2, textAlign: "center" }}>
              <LoadingButton
                className="pendo_web-downloadapp-sendlink"
                disabled={!checked || !isValidPhoneNumber(phone || "")}
                loading={loading}
                size="medium"
                variant="contained"
                onClick={handleSendDownloadLink}
              >
                Send me the link
              </LoadingButton>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
}
