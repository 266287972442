import {
  Box,
  Container,
  Drawer,
  Grid,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Maintenance from "components/maintenance";
import AppHeader from "components/navigation/AppHeader";
import LeftNav from "components/navigation/LeftNav";
import useAccess from "hooks/useAccess";
import useAuth from "hooks/useAuth";
import useCommunity from "hooks/useCommunity";
import useIdentity from "hooks/useIdentity";
import { useApp } from "lib/common/appProvider";
import cls from "lib/common/cls";

type AppLayoutProps = {
  children: ReactNode;
  leftNav?: boolean;
  rightRail?: boolean;
  appLayout?: boolean;
  removeContentPadding?: boolean;
};

type UseLayoutProps = {
  appContainerRef;
  drawerOpen: boolean;
  scrollMainContainerToTop: () => void;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  showAppLayout: boolean;
  setShowAppLayout: Dispatch<SetStateAction<boolean>>;
  toggleRightDrawer: () => void;
};

const LayoutContext = createContext<UseLayoutProps>({} as UseLayoutProps);

const StyledAppContainer = styled(Container)(({ theme }) => ({
  padding: 0,
  overflow: "hidden",

  [theme.breakpoints.up("lg")]: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },

  height: `calc(var(--vh) - ${theme.mixins.toolbar.minHeight}px)`,
  marginTop: `${theme.mixins.toolbar.minHeight}px`,
  [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
    height: `calc(var(--vh) - ${
      theme.mixins.toolbar[theme.breakpoints.up("xs")][
        "@media (orientation: landscape)"
      ].minHeight
    }px)`,
    marginTop: `${
      theme.mixins.toolbar[theme.breakpoints.up("xs")][
        "@media (orientation: landscape)"
      ].minHeight
    }px`,
  },
  [theme.breakpoints.up("sm")]: {
    height: `calc(var(--vh) - ${theme.mixins.toolbar.minHeight}px)`,
    marginTop: `${theme.mixins.toolbar.minHeight}px`,
  },

  [theme.breakpoints.up("lg")]: {
    height: `calc(var(--vh) - ${theme.mixins.toolbar.minHeight}px)`,
    marginTop: `${theme.mixins.toolbar.minHeight}px`,
  },
}));

export default function AppLayout({
  children,
  leftNav = true,
  rightRail = null,
  appLayout = true,
  removeContentPadding = false,
}: AppLayoutProps) {
  const theme = useTheme();
  const router = useRouter();
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const appContainerRef = useRef<HTMLDivElement>(null);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [isRouteChanging, setIsRouteChanging] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showAppLayout, setShowAppLayout] = useState(true);
  const { hasRouteAccess } = useAccess();

  const { loading } = useAuth();
  const { communityCode } = useIdentity();
  const site = useApp("site");
  const { community } = useCommunity({
    code: communityCode,
  });

  const showRightRail = useMediaQuery(theme.breakpoints.up("md")) && rightRail;
  const rightRailSize = useMediaQuery(theme.breakpoints.only("md")) ? 4 : 3;

  const scrollMainContainerToTop = useCallback(() => {
    mainContainerRef?.current?.scroll({
      top: 0,
    });
  }, [mainContainerRef]);

  useEffect(() => {
    setShowAppLayout(true);

    const handleScrollOnRouteChange = () => {
      setIsRouteChanging(true);
      scrollMainContainerToTop();
    };

    const handleRouteChangeComplete = () => {
      setIsRouteChanging(false);
    };

    router.events.on("routeChangeStart", handleScrollOnRouteChange);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleScrollOnRouteChange);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router, scrollMainContainerToTop]);

  const toggleRightDrawer = useCallback(() => {
    setRightDrawerOpen(!rightDrawerOpen);
  }, [rightDrawerOpen]);

  const contextValue = useMemo(() => {
    return {
      appContainerRef,
      drawerOpen,
      scrollMainContainerToTop,
      setDrawerOpen,
      setShowAppLayout,
      showAppLayout,
      toggleRightDrawer,
    };
  }, [drawerOpen, scrollMainContainerToTop, showAppLayout, toggleRightDrawer]);

  if (loading) return null;

  const isChatUrl = () => {
    const match = router.asPath.match(/messages/);
    return !!match;
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      <Box
        ref={appContainerRef}
        sx={{
          height: "var(--vh)",
          overflow: "hidden",
        }}
      >
        {!appLayout || !showAppLayout ? (
          children
        ) : (
          <>
            <AppHeader
              siteLogo={community?.headerLogo ?? site.logo}
              siteName={community?.name ?? site.name}
            />
            <StyledAppContainer maxWidth="xl">
              <Grid container height="100%" wrap="nowrap">
                {leftNav && <LeftNav />}

                <Grid
                  ref={mainContainerRef}
                  item
                  className={cls([
                    removeContentPadding && "noContentPadding",
                    !isChatUrl() && "extraPadding",
                  ])}
                  id="main-content"
                  lg={
                    // eslint-disable-next-line no-nested-ternary
                    leftNav ? (showRightRail ? 6 : 9) : showRightRail ? 9 : 12
                  }
                  md={showRightRail ? 8 : 12}
                  sx={{
                    overflowY: "auto",
                    padding: "16px",

                    "&.extraPadding": {
                      paddingBottom: "60px !important",
                    },

                    "&.noContentPadding": {
                      padding: 0,
                    },
                  }}
                  xs={12}
                >
                  {!hasRouteAccess(router.pathname) ? (
                    <Maintenance />
                  ) : (
                    children
                  )}
                </Grid>
                {!isRouteChanging && showRightRail && (
                  <Grid
                    item
                    sx={{ paddingRight: "0 !important" }}
                    xs={rightRailSize}
                  >
                    <Box
                      sx={{
                        height: `calc(var(--vh) - ${theme.mixins.toolbar.minHeight}px)`,
                        overflowY: "scroll",
                        position: "sticky",
                        top: 0,
                        borderLeft: "1px solid rgba(0,0,0,0.12)",
                        "&.MuiGrid-item": {
                          padding: 0,
                        },
                      }}
                    >
                      {rightRail}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </StyledAppContainer>
          </>
        )}
      </Box>

      {!isRouteChanging && rightRail && (
        <Drawer
          anchor="right"
          open={rightDrawerOpen}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: "282px",
            },
          }}
          onClose={() => toggleRightDrawer()}
        >
          <Toolbar />
          {rightRail}
        </Drawer>
      )}
    </LayoutContext.Provider>
  );
}

export const useLayout = () => useContext(LayoutContext);
