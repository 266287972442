import { useFlags } from "launchdarkly-react-client-sdk";
import router, { useRouter } from "next/router";
import { useEffect, useState } from "react";

import useAuth from "hooks/useAuth";
import useIdentity from "hooks/useIdentity";
import { useApp } from "lib/common/appProvider";

export default function ProtectedPage({ children, loginUrl }) {
  const { pathname, query } = useRouter();
  const { user, ldIdentified, loading } = useAuth();
  const { platformUrl } = useApp("site");
  const env = useApp("env");
  const { gate, role } = useIdentity();
  const {
    permArticles,
    permContentStudio,
    permDailyDigest,
    permExpertPanels,
    permGroups,
    permHelpCenter,
    permMediaToolkit,
    permPublishing,
    permReferMemberCopy,
  } = useFlags();

  const [page, setPage] = useState(null); // TODO: Inserting loading screen here

  useEffect(() => {
    const isLoggedIn = user?.uid;

    // If not logged in
    if (!loading && !isLoggedIn) {
      // Redirect to login page
      window.location.href = loginUrl;
      return;
    }

    if (!loading && isLoggedIn && ldIdentified) {
      // If the user is in dunning push them to /dunning
      // The user should only have access to the settings page
      if (
        gate === "dunning" &&
        !pathname.startsWith("/dunning") &&
        !pathname.startsWith("/settings")
      ) {
        router.replace("/dunning");
        return;
      }

      // If the user is not in dunning they do not have access to the dunning page
      if (gate !== "dunning" && pathname.startsWith("/dunning")) {
        router.replace("/");
        return;
      }

      // If route is Groups but user does not have permission to access Groups feature
      if (pathname.startsWith("/groups") && permGroups === false) {
        // Redirect to home
        router.replace("/");
        return;
      }

      // If route is Notifications Settings but user does not have permission to access Daily Digest feature
      if (
        pathname.startsWith("/settings") &&
        query?.tab === "notifications" &&
        permDailyDigest === false
      ) {
        // Redirect to settings home
        router.replace("/settings");
        return;
      }

      // If route is Publishing but user does not have permission to access Publishing feature or any sub-feature of Publishing
      if (
        pathname.startsWith("/publishing") &&
        permPublishing === false &&
        permArticles === false &&
        permContentStudio === false &&
        permExpertPanels === false
      ) {
        // Redirect to home
        router.replace("/");
        return;
      }

      // If route is Expert Panels but user does not have permission to access Expert Panels feature
      if (
        pathname.startsWith("/publishing/expert-panels") &&
        permExpertPanels === false
      ) {
        // Redirect to publishing home
        router.replace("/publishing");
        return;
      }

      // If route is refer member but user belongs to DEI community
      if (pathname.startsWith("/refer") && !permReferMemberCopy?.confirmation) {
        // Redirect to home
        router.replace("/");
        return;
      }

      // If route is Media Toolkit but user does not have permission to access Media Toolkit feature
      if (pathname.startsWith("/media-toolkit") && permMediaToolkit === false) {
        // Redirect to home
        router.replace("/");
        return;
      }

      // If route is Help Center but user does not have permission to access Help Center feature
      if (pathname.startsWith("/help-center") && permHelpCenter === false) {
        // Redirect to home
        router.replace("/");
        return;
      }

      setPage(children);
    }
  }, [
    children,
    env,
    gate,
    ldIdentified,
    loading,
    loginUrl,
    pathname,
    permArticles,
    permContentStudio,
    permDailyDigest,
    permExpertPanels,
    permGroups,
    permHelpCenter,
    permMediaToolkit,
    permPublishing,
    permReferMemberCopy?.confirmation,
    platformUrl,
    query?.tab,
    role,
    user?.uid,
  ]);

  return page;
}
